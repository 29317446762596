import ParentModel from '@/models/parent/ParentModel.js';

export default class SystemTag extends ParentModel {
   constructor(data) {
      super(data);
      if (data != null) {
         this.system_id = data.system_id;
         this.position = (typeof (data.position) === 'string') ? JSON.parse(data.position) : data.position;
      } else {
         this.system_id = null;
         this.position = {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
         };
      }
   }

   get toDto() {
      const dto = {};
      dto.system_id = this.system_id;
      dto.position = (typeof (this.position) === 'object') ? JSON.stringify(this.position) : this.position;
      return dto;
   }
}
