var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.selfItem)?_c('div',{staticClass:"se_main"},[_c('div',{staticClass:"bottombuttons"},[_c('v-btn',{staticClass:"_bts ma-2",attrs:{"elevation":"2","width":"15px","height":"50px"},on:{"click":_vm.closeSvgEditor}},[_c('v-icon',{staticClass:"ma-2"},[_vm._v("mdi-close")])],1),_c('v-btn',{staticClass:"_bts ma-2",class:{ bluebtn: _vm.mode == 'edit' },attrs:{"elevation":"2","width":"15px","height":"50px"},on:{"click":_vm.editMode}},[_c('v-icon',{staticClass:"ma-2"},[_vm._v("mdi-tag-text-outline")])],1),_c('v-btn',{staticClass:"_bts ma-2",class:{ bluebtn: _vm.mode == 'draw' },attrs:{"elevation":"2","width":"15px","height":"50px"},on:{"click":_vm.drawMode}},[_c('v-badge',{attrs:{"content":1,"value":_vm.newTag,"color":"red"}},[_c('v-icon',{staticClass:"ma-2"},[_vm._v("mdi-tag-plus-outline")])],1)],1),_c('v-btn',{staticClass:"_bts ma-2",class:{ bluebtn: _vm.showTaglist},attrs:{"elevation":"2","width":"15px","height":"50px"},on:{"click":function($event){_vm.showTaglist = !_vm.showTaglist}}},[_c('v-icon',{staticClass:"ma-2"},[_vm._v("mdi-format-list-bulleted-type")])],1)],1),(_vm.showTaglist)?_c('SystemTagsCats',{attrs:{"itemb":_vm.selfItem},on:{"hideTagList":_vm.hideTagList,"hidetag":_vm.hidetag,"gototag":_vm.gototag}}):_vm._e(),(true)?_c('panZoom',{ref:"_panzoom",staticClass:"image-viewer",attrs:{"options":{
         initialZoom: 1,
         minZoom: 0.3,
         maxZoom: 10,
         initialX: _vm.penzoompos.x,
         initialY: _vm.penzoompos.y,
      }},on:{"init":_vm.onPanZoomInit}},[_c('div',{ref:"eli"},[_c('v-img',{attrs:{"src":_vm.selfItem.file_svg}}),_c('v-stage',{ref:"stage",staticClass:"drawstage",class:{ pointer_event_none: _vm.disable_pointer_event },attrs:{"config":_vm.stageSize},on:{"mouseDown":_vm.handleMouseDown,"mouseUp":_vm.handleMouseUp,"mousemove":_vm.handleMouseMove}},[_c('v-layer',{ref:"layer"},_vm._l((_vm.recs),function(rec,index){return _c('v-rect',{key:'rect'+index,staticClass:"recclass",attrs:{"config":{
                     id: 'tagId' + index,
                     draggable: true,
                     visible: rec.visible,
                     x: Math.min(rec.position.startPointX, rec.position.startPointX + rec.position.width),
                     y: Math.min(rec.position.startPointY, rec.position.startPointY + rec.position.height),
                     width: Math.abs(rec.position.width),
                     height: Math.abs(rec.position.height),
                     fill: 'rgb(0,80,255,0.1)',
                     stroke: 'black',
                     listening: true,
                     strokeWidth: 0.9, }},on:{"mouseUp":_vm.rectMouseUp,"dragend":_vm.rectDragEnd,"mouseover":_vm.rectMouseOver,"mouseout":_vm.rectMouseOut}})}),1)],1)],1)]):_vm._e(),_c('SystemTagForm',{directives:[{name:"show",rawName:"v-show",value:(_vm.editTagFormOpen),expression:"editTagFormOpen"}],attrs:{"itema":_vm.tagitem},on:{"call_form_delete":_vm.form_delete,"call_form_close":_vm.form_close}}),_c('SystemTagInf',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editTagFormOpen),expression:"!editTagFormOpen"}],attrs:{"itematagitem":_vm.tagitem,"itemAbsolutePosition":_vm.infPosition},on:{"showEditTagForm":_vm.showEditTagForm}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }