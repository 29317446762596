<template>
   <!-- <v-slide-x-reverse-transition> -->
      <!-- v-if="selfItem != null" -->
   <div
      class="tagForm pa-5 fill-height rounded-l-lg elevation-8">

      <v-row>
         <v-col class="d-flex justify-end">
            <v-icon @click="form_close"> mdi-close </v-icon>
         </v-col>
      </v-row>

      <!-- {{categories}} -->
      <v-form ref="form" v-model="valid" lazy-validation v-if="selfItem">
         <div class="text-h6 black--text text-center">
         <span v-if="selfItem.id">Edit</span>
         <span v-if="!selfItem.id">Add</span>
          Tag
      </div>
      <br>
         <v-text-field
            v-model="selfItem.tag_name"
            label="Title"></v-text-field>
         <br />
         <v-text-field
            v-model="selfItem.short_desc"
            label="Short Description"></v-text-field>
         <!-- <v-btn @click="filescount++">xx</v-btn> -->
         <span> Files </span>

         <div v-for="(file, index) in filesPointer">
            <a :href=file.file target="_blank">{{file.name}}</a>
            <v-icon @click="removeFromfilesPointer(index)">mdi-close-circle</v-icon>
         </div>
         <br />
         <span> Add files </span>
         <div v-for="(nfile, index) in newFilesPointer">
            {{nfile.name}}
            <v-icon @click="removeFromNewfilesPointer(index)">mdi-close-circle</v-icon>
         </div>
         <br>

         <v-file-input type="file"
                       file-accept="pdf"
                       label="File input"
                       @change="fileUpload"
         ></v-file-input>

         <v-select
            v-if="categories"
            :items="childrenTest(categories)"
            v-model="selectedCat"
            :menu-props="{ zIndex: 1000 }"
            item-text="title"
            item-value="children"
            label="Choose Category">
         </v-select>
         <v-select
            v-if="selectedCat"
            :items="selectedCat"
            v-model="selectedSubCat"
            :menu-props="{ zIndex: 1000 }"
            item-text="title"
            item-value="id"
            label="Choose Category">
            <template v-slot:selection="{ item }" class="">
               <v-img
                  :src="getWhiteSVGimageURL(item.icon)"
                  max-height="25"
                  max-width="25"
                  contain
                  class="mr-2"
               ></v-img>
               <span
                  class="d-inline-block text-truncate"
                  style="max-width: 180px;"
               >{{ item.title }}</span
               >
            </template>
            <template v-slot:item="{active, item, attrs, on }">
               <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                     <v-img
                        :src="getWhiteSVGimageURL(item.icon)"
                        max-height="35"
                        max-width="35"
                        contain
                     ></v-img>
                  </v-list-item-icon>
                  <v-list-item-content>
                     <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
               </v-list-item>
            </template>
         </v-select>
         <v-checkbox v-if="newTag" v-model="useNewTag" label="Assign model's tag"></v-checkbox>
         
         <br><br>
         <v-btn :disabled="!valid" color="info" class="mr-4 mb-4" @click="submit"> Apply </v-btn>
         <v-btn @click="form_delete" class="mb-4"> Delete </v-btn>
         <v-btn v-if="selfItem.point_cloud_tag_id" class="mb-4" @click="openPointcloud">View in model</v-btn>
      </v-form>
   </div>
   <!-- </v-slide-x-reverse-transition> -->
</template>

<script>
import SystemTagService from '@/service/SystemTagService.js';
import SystemTag from '@/models/SystemTag.js';
import CategoryService from '@/service/CategoryService.js';
import PointCloudTagsService from '@/service/PointCloudTagService.js';

export default {
   name: 'SystemTagForm',
   props: {
      itema: {
         type: Object,
         default: null,
      },
   },
   data: () => ({
      fff: [],
      selfItem: null,
      valid: false,
      items: null,
      cats: null,
      categories: null,
      selectedCat: null,
      selectedSubCat: null,
      scrolled: false,
      filescount: 1,
      files: null,
      filesPointer: [],
      newFilesPointer: [],
      newTag: false,
      useNewTag: false,
   }),
   watch: {
      itema: function (val) {
         if (val !== null) {
            this.filesPointer = [];
            this.newFilesPointer = [];
            const systemTagService = new SystemTagService();
            if (val.id) {
               systemTagService.show(val.id).then((res) => {
                  this.selfItem = new SystemTag(res.data);
                  this.selfItem.position = { ...val.position };
                  this.selectedCat = this.getParentByKidID(this.selfItem.category_id).children;
                  this.selectedSubCat = this.selfItem.category_id;
                  this.filesPointer = this.selfItem.system_tag_files;
               });
            } else {
               this.selfItem = val;
               this.selectedCat = this.getParentByKidID(this.selfItem.category_id).children;
               this.selectedSubCat = this.selfItem.category_id;
            }
         } else {
            this.$refs.form.reset();
            this.selfItem = null;
            this.filesPointer = [];
            this.newFilesPointer = [];
         }
      },
   },
   mounted() {
      this.callServiceCatsList();
      window.addEventListener('scroll', this.scrollListener);
      this.newTag = localStorage.getItem('newTag');
      if (this.newTag) {
         this.useNewTag = true;
         this.newTag = JSON.parse(this.newTag);
      }
   },
   methods: {
      openPointcloud() {
         this.$store.dispatch('storeIframeUrl', this.itema.point_cloud_tag.point_cloud.id);

         setTimeout(() => {
            window.potreeFrame.postMessage({ type: 'jumToTagId', data: { point_clould_tag_id: this.selfItem.point_cloud_tag_id } }, '*');
         }, 2000);
      },
      removeFromNewfilesPointer(index) {
         this.newFilesPointer.splice(index, 1);
      },
      removeFromfilesPointer(index) {
         this.filesPointer.splice(index, 1);
      },
      fileUpload(event) {
         // console.log('<< < file Upload > >>');
         this.submitFiles(event);
      },
      submitFiles(fileitem) {
         if (fileitem) {
            const systemTagService = new SystemTagService();
            systemTagService.upload(fileitem).then((res) => {
               // console.log('res', res.status);
               if (res.status === 200) {
                  // console.log('**-** ', fileitem);
                  this.newFilesPointer.push(res.data);
               }
            });
         }
      },
      gotoModel() {
         // console.log('itama ', this.itema.point_cloud_tag.point_cloud.url);
         // window.potreeFrame.postMessage({ type: 'jumToTagId', data: { point_clould_tag_id: this.selfItem.point_cloud_tag_id } }, '*');
      },
      getWhiteSVGimageURL(val) {
         return `${process.env.VUE_APP_API}/svg/ffffff/${val}.svg`;
      },
      childrenTest(cats) {
         let i = 0;
         const result = [];
         for (i = 0; i < cats.length; i += 1) {
            if (typeof (cats[i].children) !== 'undefined') {
               result.push(cats[i]);
            }
         }
         return result;
      },
      getParentByKidID(id) {
         if (id) {
            const cats = this.categories;
            let i = 0;
            let child;
            let parent;
            for (i = 0; i < cats.length; i += 1) {
               child = this.getItemById(cats[i].children, id);
               if (child) {
                  parent = this.getItemById(this.categories, child.parent_id);
                  return parent || '';
               }
            }
         }
         return '';
      },
      getItemById(cats, id) {
         if ((cats) && (id)) {
            let i;
            let cat = null;
            for (i = 0; i < cats.length; i += 1) {
               cat = cats[i];
               if (cat.id === id) return cat;
            }
         }
         return null;
      },
      catChosen() {
         /// /console.log(e);
      },
      callServiceCatsList() {
         // console.log('__-__-__-__');
         const categoryService = new CategoryService(this);
         categoryService.list().then((list) => {
            if (list) {
               this.categories = list.data.data;
            }
         });
      },
      form_delete() {
         this.$emit('call_form_delete');
         this.filesPointer = [];
         this.newFilesPointer = [];
      },
      form_close() {
         this.filesPointer = [];
         this.newFilesPointer = [];
         this.$emit('call_form_close');
      },
      submit() {
         // include filesPointer
         const data = new SystemTag({
            site_id: this.selfItem.site_id,
            system_id: this.selfItem.system_id,
            category_id: this.selectedSubCat,
            tag_name: this.selfItem.tag_name,
            position: `${this.selfItem.position.startPointX},${this.selfItem.position.startPointY},${this.selfItem.position.startPointX + this.selfItem.position.width},${this.selfItem.position.startPointY + this.selfItem.position.height}`,
            short_desc: this.selfItem.short_desc,
            system_tag_files: JSON.stringify(this.filesPointer),
            new_system_tag_files: JSON.stringify(this.newFilesPointer),
         });
         const tagService = new SystemTagService();
         if (!this.selfItem.id) {
            tagService.add(data).then((res) => {
               if (this.newTag && this.useNewTag) {
                  const dataa = {
                     point_cloud_id: this.newTag.point_cloud_id,
                     site_id: this.newTag.site_id,
                     icon_pos: this.newTag.icon_pos,
                     cam_pos: this.newTag.cam_pos,
                     system_tag_id: res.data.id,
                  };
                  const pointCloudTagsService = new PointCloudTagsService();
                  pointCloudTagsService.store(dataa).then((resa) => {
                     if (resa) {
                        this.newTag = null;
                        this.useNewTag = null;
                        localStorage.removeItem('newTag');
                        this.form_close();
                      }
                  });
               } else {
                  this.form_close();
               }
            });
         } else {
            tagService.update(this.selfItem.id, data).then((res) => {
               this.form_close();
            });
         }
      },
      scrollListener: function () {
         if (window.scrollY > 65) { this.scrolled = true; } else { this.scrolled = false; }
      },
   },
   beforeDestroy: function () {
      window.removeEventListener('scroll', this.scrollListener);
   },
};
</script>
<style scoped>
.formclosebtn{
   position:absolute;
   top: 0;
   right: 0;
   width:40px;
}
.formtreecontainer {
   height: 350px;
   background-color: #f8f8f8;
   overflow-y: scroll;
   margin-bottom: 20px;
   padding-top: 10px;
   padding-bottom: 10px;
}
.tagForm {
   width: 325px;
   position: fixed;
   top: 63px;
   right: 0;
   z-index: 10;
   background-color: rgb(255, 255, 255);
}

</style>
