<template>
   <div class="se_main" v-if="selfItem">
      <div class="bottombuttons">
         <v-btn elevation="2"
                width= '15px'
                height="50px"
                class="_bts ma-2"
                @click="closeSvgEditor">
            <v-icon class="ma-2">mdi-close</v-icon>
         </v-btn>
         <v-btn elevation="2"
                width= '15px'
                height="50px"
                @click="editMode"
                class="_bts ma-2"
                v-bind:class="{ bluebtn: mode == 'edit' }">
            <v-icon class="ma-2">mdi-tag-text-outline</v-icon>
         </v-btn>

         <v-btn elevation="2"
                width= '15px'
                height="50px"
                @click="drawMode"
                class="_bts ma-2"
                v-bind:class="{ bluebtn: mode == 'draw' }">
            <v-badge
               :content="1"
               :value="newTag"
               color="red"
            >
               <v-icon class="ma-2">mdi-tag-plus-outline</v-icon>
            </v-badge>
         </v-btn>

         <v-btn elevation="2"
                width= '15px'
                height="50px"
                @click="showTaglist = !showTaglist"
                class="_bts ma-2"
                v-bind:class="{ bluebtn: showTaglist}">
            <v-icon class="ma-2">mdi-format-list-bulleted-type</v-icon>
         </v-btn>
         <!-- <v-btn elevation="2" width= '15px' height="50px" @click="gototag(0)" class="_bts ma-2"> <v-icon class="ma-2">2</v-icon> </v-btn>
         <v-btn elevation="2" width= '15px' height="50px" @click="gototag(1)" class="_bts ma-2"> <v-icon class="ma-2">2</v-icon> </v-btn>
         <v-btn elevation="2" width= '15px' height="50px" @click="gototag(2)" class="_bts ma-2"> <v-icon class="ma-2">2</v-icon> </v-btn> -->

      </div>

      <!-- {{mode}} -->

      <SystemTagsCats
         v-if="showTaglist"
         :itemb="selfItem"
         @hideTagList="hideTagList"
         @hidetag="hidetag"
         @gototag="gototag"
      />

      <panZoom
         v-if="true"
         @init="onPanZoomInit"
         class="image-viewer"
         ref="_panzoom"
         :options="{
            initialZoom: 1,
            minZoom: 0.3,
            maxZoom: 10,
            initialX: penzoompos.x,
            initialY: penzoompos.y,
         }">
         <div ref="eli">
            <v-img :src="selfItem.file_svg"/>
            <v-stage
               class= "drawstage"
               ref= "stage"
               :config= "stageSize"
               @mouseDown= "handleMouseDown"
               @mouseUp= "handleMouseUp"
               @mousemove= "handleMouseMove"
               v-bind:class= "{ pointer_event_none: disable_pointer_event }">
               <v-layer ref= "layer">
                  <v-rect
                     v-for= "(rec, index) in recs"
                     :key= "'rect'+index"
                     @mouseUp= "rectMouseUp"
                     @dragend= "rectDragEnd"
                     @mouseover= "rectMouseOver"
                     @mouseout= "rectMouseOut"
                     class="recclass"
                     :config="{
                        id: 'tagId' + index,
                        draggable: true,
                        visible: rec.visible,
                        x: Math.min(rec.position.startPointX, rec.position.startPointX + rec.position.width),
                        y: Math.min(rec.position.startPointY, rec.position.startPointY + rec.position.height),
                        width: Math.abs(rec.position.width),
                        height: Math.abs(rec.position.height),
                        fill: 'rgb(0,80,255,0.1)',
                        stroke: 'black',
                        listening: true,
                        strokeWidth: 0.9, }" />
               </v-layer>
            </v-stage>

         </div>
      </panZoom>

      <SystemTagForm
         v-show="editTagFormOpen"
         :itema="tagitem"
         @call_form_delete="form_delete"
         @call_form_close="form_close"/>

      <SystemTagInf
         v-show="!editTagFormOpen"
         :itematagitem="tagitem"
         :itemAbsolutePosition="infPosition"
         @showEditTagForm="showEditTagForm"/>

   </div>
</template>

<script>

import System from '@/models/System.js';
import SystemTagForm from '@/components/SystemTagForm.vue';
import SystemTagInf from '@/components/SyeytemTagInf.vue';
import SystemTagService from '@/service/SystemTagService.js';
import SystemTag from '@/models/SystemTag.js';
import SystemTagsCats from '@/components/SystemTagsCats.vue';

const width = window.innerWidth;
const height = window.innerHeight;

export default {
   components: {
      SystemTagForm, SystemTagInf, SystemTagsCats,
   },
   props: {
      svgItem: {
         type: Object,
         default: null,
      },
      focusTarget: {
         type: Number,
         default: null,
      },
   },
   watch: {
      svgItem: function (val) {
         if (val != null) {
            this.selfItem = new System(val);
            this.getTags();
         } else {
            this.selfItem = null;
         }
      },
      focusTarget: function (val) {
         if (val != null) {
            setTimeout(() => {
               this.gototag(val);
            }, 1000);
         }
      },
   },
   data() {
      return {
         tagitem: null,
         stageSize: {
            width: width,
            height: height,
         },
         lines: [],
         isDrawing: false,
         recs: [],
         recs_fromServ: [],
         disable_pointer_event: true,
         coords_start: null,
         coords_end: null,
         movingMode: false,
         selfItem: null,
         position: '',
         mode: 'panzoom',
         penzoompos1: { x: 0, y: 0 },
         penzoompos: { x: 0, y: 0 },
         panZoomElem: null,
         focusSystemTagId: null,
         overRect: false,
         showTaglist: true,
         infPosition: { x: 200, y: 200 },
         editTagFormOpen: false,
         newTag: false,
      };
   },
   mounted() {
      this.newTag = localStorage.getItem('newTag');
      console.log('hello', this.newTag);
   },
   methods: {
      showEditTagForm() {
         this.editTagFormOpen = true;
      },
      resizable() {
         const d = {};
         document.addEventListener('mousedown', (e) => {
            const closestDialog = e.target.closest('.v-dialog.v-dialog--active');
            if (e.button === 0 && closestDialog != null && e.target.classList.contains('v-card__title')) { // element which can be used to move element
               d.el = closestDialog; // element which should be moved
               d.mouseStartX = e.clientX;
               d.mouseStartY = e.clientY;
               d.elStartX = d.el.getBoundingClientRect().left;
               d.elStartY = d.el.getBoundingClientRect().top;
               d.el.style.position = 'fixed';
               d.el.style.margin = 0;
               d.oldTransition = d.el.style.transition;
               d.el.style.transition = 'none';
            }
         });
         document.addEventListener('mousemove', (e) => {
            if (d.el === undefined) return;
            d.el.style.left = `${Math.min(
               Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
               window.innerWidth - d.el.getBoundingClientRect().width,
            )}px`;
            d.el.style.top = `${Math.min(
               Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
               window.innerHeight - d.el.getBoundingClientRect().height,
            )}px`;
         });
         document.addEventListener('mouseup', () => {
            if (d.el === undefined) return;
            d.el.style.transition = d.oldTransition;
            d.el = undefined;
         });
         setInterval(() => { // prevent out of bounds
            const dialog = document.querySelector('.v-dialog.v-dialog--active');
            if (dialog === null) return;
            dialog.style.left = `${Math.min(parseInt(dialog.style.left), window.innerWidth - dialog.getBoundingClientRect().width)}px`;
            dialog.style.top = `${Math.min(parseInt(dialog.style.top), window.innerHeight - dialog.getBoundingClientRect().height)}px`;
         }, 100);
      },
      hideTagList() {
         this.showTaglist = false;
      },
      afterGettingTags() {
         this.setEditMode('edit');
         this.showTaglist = true;
         window.addEventListener('mouseup', () => {
            if (this.mode !== 'draw') {
               this.handleMouseUp();
            }
         });
         window.addEventListener('wheel', (event) => {
            // const delta = Math.sign(event.deltaY);
            this.setEditMode('panzoom');
         });
      },
      rectMouseOver(e) {
         const stage = e.target.getStage();
         stage.container().style.cursor = 'pointer';
         this.overRect = true;
      },
      rectMouseOut(e) {
         const stage = e.target.getStage();
         stage.container().style.cursor = 'default';
         this.overRect = false;
      },
      onPanZoomInit(e) {
         this.panZoomElem = e;
      },
      gototag(tagid) {
         const tag = this.getIndexOfRecId(tagid);
         const recMiddle = {
            x: this.recs[tag].position.startPointX + (this.recs[tag].position.width / 2),
            y: this.recs[tag].position.startPointY + (this.recs[tag].position.height / 2),
         };
         const scrMiddle = {
            x: this.$refs.eli.clientWidth / 2,
            y: (this.$refs.eli.clientHeight / 2) - 60,
         };

         const zoomFactor = 3;
         const diff1 = {
            x: scrMiddle.x - recMiddle.x,
            y: scrMiddle.y - recMiddle.y,
         };
         const diff = {
            x: scrMiddle.x - (recMiddle.x * zoomFactor),
            y: scrMiddle.y - (recMiddle.y * zoomFactor),
         };

         this.penzoompos1.x = diff1.x * 1;
         this.penzoompos1.y = diff1.y * 1;

         this.penzoompos.x = diff.x * 1;
         this.penzoompos.y = diff.y * 1;

         this.panZoomElem.zoomAbs(this.penzoompos1.x, this.penzoompos1.y, zoomFactor);
         setTimeout(() => {
            this.panZoomElem.smoothMoveTo(this.penzoompos.x, this.penzoompos.y);
         }, 10);
      },
      hidetag(tagid, event) {
         const tag = this.getIndexOfRecId(tagid);
         this.recs[tag].visible = !this.recs[tag].visible;
      },
      getIndexOfRecId(id) {
         let myi = -1;
         for (let i = 0; i < this.recs.length; i += 1) {
            if (this.recs[i].id === id) {
               myi = i;
               break;
            }
         }
         return myi;
      },
      rectDragEnd(e) {
         this.recs[e.target.index].position.startPointX = e.target.attrs.x;
         this.recs[e.target.index].position.startPointY = e.target.attrs.y;
         this.recs[e.target.index].position.width = e.target.attrs.width;
         this.recs[e.target.index].position.height = e.target.attrs.height;
         this.tagitem = new SystemTag(this.recs[e.target.index]);
      },
      rectMouseUp(e) {
         if (this.mode === 'edit') {
            this.tagitem = new SystemTag(this.recs[e.target.index]);
            this.infPosition.x = ((this.tagitem.position.startPointX + this.tagitem.position.width + 10) * this.panZoomElem.getTransform().scale) + this.panZoomElem.getTransform().x;
            this.infPosition.y = ((this.tagitem.position.startPointY) * this.panZoomElem.getTransform().scale) + this.panZoomElem.getTransform().y;
         }
      },
      getTags() {
         this.newTag = localStorage.getItem('newTag');
         const tagService = new SystemTagService(this.selfItem);
         tagService.getAll(this.selfItem.id).then((res) => {
            if (res) {
               this.recs = this.sRecsToRecs(res.data);
               this.afterGettingTags();
            } else {
               console.error("getAll didn't get nothing");
            }
         });
      },
      sRecsToRecs(srecs) {
         const r = [];
         for (let i = 0; i < srecs.length; i += 1) {
            const element = srecs[i];
            const pos = element.position.split(',');
            r.push({
               category_id: element.category_id,
               id: element.id,
               position: {
                  startPointX: parseInt(pos[0]),
                  startPointY: parseInt(pos[1]),
                  width: parseInt(pos[2] - pos[0]),
                  height: parseInt(pos[3] - pos[1]),
               },
               site_id: element.site_id,
               system_id: element.system_id,
               tag_name: element.tag_name,
               short_desc: element.short_desc,
               system_tag_files: element.system_tag_files,
               visible: true,
               point_cloud_tag: element.point_cloud_tag,
            });
         }
         return r;
      },
      closeSvgEditor() {
         this.$emit('close-svg-editor');
         this.selfItem = null;
      },
      form_close() {
         this.tagitem = null;
         this.mode = '';
         this.editTagFormOpen = false;
         this.getTags();
      },
      form_delete() {
         if (this.tagitem.id) {
            const tagService = new SystemTagService();
            tagService.delete(this.tagitem.id).then((res) => {
            });
         }
         this.tagitem = null;
         this.getTags();
      },
      handleDragstart(e) {
         if (this.mode !== 'draw') {
            return;
         }
         this.dragItemId = e.target.id();
         const item = this.recs.find((i) => i.id === this.dragItemId);
         const index = this.recs.indexOf(item);
         this.recs.splice(index, 1);
         this.recs.push(item);
      },
      editMode() {
         if (this.mode !== 'edit') {
            this.$refs._panzoom.pause();
            this.disable_pointer_event = false;
            this.mode = 'edit';
         } else {
            this.$refs._panzoom.resume();
            this.disable_pointer_event = true;
            this.mode = 'panzoom';
         }
      },
      setEditMode(mod) {
         this.mode = mod;
         switch (mod) {
         case 'edit':
            if (this.$refs._panzoom) { this.$refs._panzoom.pause(); }
            this.disable_pointer_event = false;
            break;
         case 'panzoom':
            if (this.$refs._panzoom) { this.$refs._panzoom.resume(); }
            this.disable_pointer_event = true;
            break;
         default:
            break;
         }
      },
      drawMode() {
         if (this.mode !== 'draw') {
            this.$refs._panzoom.pause();
            this.disable_pointer_event = false;
            this.mode = 'draw';
         } else {
            this.$refs._panzoom.resume();
            this.disable_pointer_event = true;
            this.mode = 'panzoom';
         }
      },
      dragMod() {
         this.$refs._panzoom.pause();
         this.disable_pointer_event = false;
         this.movingMode = true;
      },
      handleMouseDown() {
         if (this.overRect) { return false; }
         if (this.mode !== 'draw') {
            this.setEditMode('panzoom');
         }

         if (this.mode !== 'draw') {
            return false;
         }

         if (this.movingMode) {
            return false;
         }
         this.isDrawing = true;
         const pos = this.$refs.stage.getNode().getPointerPosition();
         this.coords_start = pos;
         this.setRecs([
            ...this.recs,
            {
               system_id: this.selfItem.id,
               company_id: this.selfItem.company_id,
               site_id: this.selfItem.site_id,
               tag_name: '',
               short_desc: '',
               position: {
                  startPointX: pos.x, startPointY: pos.y, width: 0, height: 0,
               },
            },
         ]);
         return true;
      },
      handleMouseUp() {
         if (this.mode !== 'draw') { this.setEditMode('edit'); }
         if (this.mode === 'edit') { return false; }
         if (this.movingMode) { return false; }
         if (!this.isDrawing) { return false; }
         this.isDrawing = false;
         const pos = this.$refs.stage.getNode().getPointerPosition();
         this.coords_end = pos;
         this.tagitem = this.recs[this.recs.length - 1];
         this.editTagFormOpen = true;
         this.$refs._panzoom.resume();
         this.disable_pointer_event = true;
         this.coords_start = null;
         this.coords_end = null;
         return true;
      },
      setRecs(element) {
         this.recs = element;
      },
      handleMouseMove() {
         if (this.movingMode) {
            return false;
         }
         if (!this.isDrawing) {
            return false;
         }
         const point = this.$refs.stage.getNode().getPointerPosition();
         const curRec = this.recs[this.recs.length - 1];

         curRec.position.width = point.x - curRec.position.startPointX;
         curRec.position.height = point.y - curRec.position.startPointY;
         return true;
      },
   },
};
</script>
<style scoped>

</style>
