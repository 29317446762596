import ParentService from '@/service/ParentService.js';

export default class PointCloudTagService extends ParentService {
   store(dto) {
      const url = `${super.baseUrl}point_cloud_tags`;
      return super.customPost(url, dto);
   }

   list(id) {
      const url = `${super.baseUrl}point_cloud_tags/cloud/${id}`;
      return super.customGet(url);
   }

   delete(id) {
      const url = `${super.baseUrl}point_cloud_tags/${id}`;
      return super.customDelete(url);
   }

   updateOrCreate(dto) {
      const url = `${super.baseUrl}point_cloud_tags/`;
      return super.customPut(url, dto);
   }
}
