import ParentService from '@/service/ParentService.js';

export default class SystemTagService extends ParentService {
   show(id) {
      const url = `${super.baseUrl}system_tags/${id}`;
      return super.customGet(url);
   }

   update(id, dto) {
      const url = `${super.baseUrl}system_tags/${id}`;
      return super.customPut(url, dto);
   }
}
