import ParentModel from '@/models/parent/ParentModel.js';

export default class System extends ParentModel {
   constructor(data) {
      super(data);
      if (data != null) {
         this.id = data.id;
         this.company_id = data.company_id;
         this.site_id = data.site_id;
         this.title = data.title;
         this.short_desc = data.short_desc;
         this.file_svg = data.file_svg;
         this.file_thumb = data.file_thumb;
         this.is_active = data.is_active;
      } else {
         this.id = '';
         this.company_id = '';
         this.site_id = '';
         this.title = '';
         this.short_desc = '';
         this.file_svg = '';
         this.file_thumb = '';
         this.is_active = '0';
      }
   }

   get toDto() {
      let dto = {};
      dto.id = this.id;
      dto.company_id = this.company_id;
      dto.site_id = this.site_id;
      dto.title = this.title;
      dto.short_desc = this.short_desc;
      dto.file_svg = this.file_svg;
      dto.file_thumb = this.file_thumb;
      dto.is_active = this.is_active;
      return dto;
   }
}
