<template>
   <v-slide-x-reverse-transition>
      <div class="reclist">
        <v-col class="d-flex justify-end">
         <v-icon @click="hideThisTagList()"> mdi-close </v-icon>
        </v-col>
         <!-- {{itemb}} -->
         <div class="text-h6 black--text text-center"> Tags </div>
         <div>
            <!-- {{tagsCategoriesed}} -->
         </div>
         <!--{{tagsCategoriesed.length}}-->

         <v-treeview :items="tagsCategoriesed">
            <template v-slot:label="{ item }">
               <span @click.stop="clickTag(item)">
                  <v-icon v-if="item.parent_id === undefined"
                          :class="{ 'eyefade': true }"
                          @click.stop="showHideTag(item)">mdi-eye</v-icon>
                  {{item.name}}
               </span>
            </template>
         </v-treeview>

      </div>
   </v-slide-x-reverse-transition>
</template>
<style scoped>
.eyefade{
   opacity: 0.3;
}
</style>
<script>

import SystemTagService from '@/service/SystemTagService.js';

export default {
   props: {
      itemb: {
         type: Object,
         default: null,
      },
   },
   data: () => ({
      selfitem: null,
      tagsCategoriesed: null,
   }),
   watch: {
      itemb: function (val) {
        //console.log('----------------------------------------', val);
         if (val) {
           //console.log(val);
            this.selfitem = val;
            this.getSelialized();
         }
      },
   },
   methods: {
      hideThisTagList() {
         this.$emit('hideTagList');
      },
      clickTag(item) {
         if (item.parent_id === undefined) {
           //console.log('_-_-_', item);
            // zoom to this tag id
            this.$emit('gototag', item.system_tag_id);
         }
      },
      showHideTag(item) {
         if (item.parent_id === undefined) {
            this.$emit('hidetag', item.system_tag_id);
         }
      },
      getSelialized() {
        //console.log('getSelialized');
         const tagService = new SystemTagService(this.selfItem);
         // if (!this.itemb) {//console.log('no selfitem in systemTagsCats.vue'); return; }
         // if (!this.itemb.id) {//console.log('selfitem doen not contain id in systemTagsCats.vue'); return; }
         tagService.getCategoriesed(this.itemb.id).then((res) => {
            if (res) {
              //console.log('_data_data_', res.data.items);
               this.tagsCategoriesed = res.data.items;
            } else {
               console.error("getAll didn't get nothing");
            }
         });
      },
   },
   mounted() {
      this.getSelialized();
   },
};
</script>
